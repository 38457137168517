import React from "react";
import { Elements } from "prismic-richtext";
import { linkResolver } from "./linkResolver";
import { Link as PrismicLink } from "prismic-reactjs";
import { Link } from "gatsby";

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
const htmlSerializer = function (type, element, content, children, key) {
  var props = {};

  switch (type) {
    case Elements.heading1: {
      props = {
        className: "mb-7 mt-5 font-semibold",
      };
      return React.createElement(
        "h1",
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Elements.heading2: {
      props = {
        className: "mb-7 mt-5 font-semibold ",
      };
      return React.createElement("p", propsWithUniqueKey(props, key), children);
    }
    case Elements.heading3: {
      props = {
        className: "mb-7 mt-5 font-semibold",
      };
      return React.createElement(
        "h3",
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Elements.heading4: {
      props = {
        className: "mb-7 mt-5 font-semibold",
      };
      return React.createElement(
        "h4",
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Elements.paragraph: // Paragraph
      props = { className: "mb-7" };
      return React.createElement("p", propsWithUniqueKey(props, key), children);
    case Elements.listItem: // Unordered List Item
      props = { className: "mb-2" };
      return React.createElement(
        "li",
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.oListItem: // Ordered List Item
      props = { className: "mb-2" };
      return React.createElement(
        "li",
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.list: // Unordered List
      props = { className: "mb-6" };
      return React.createElement(
        "ul",
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.oList: // Ordered List
      props = { className: "mb-6" };
      return React.createElement(
        "ol",
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.image: {
      const img = React.createElement("img", {
        className: "",
        src: element.url,
        alt: element.alt || "",
      });
      return React.createElement(
        "p",
        propsWithUniqueKey(
          {
            className: [
              element.label || "",
              "flex items-center justify-center mb-6",
            ].join(" "),
          },
          key
        ),
        img
      );
    }
    // case Elements.image: // Image
    //   const linkUrl = element.linkTo
    //     ? element.linkTo.url || linkResolver(element.linkTo)
    //     : null;
    //   const linkTarget =
    //     element.linkTo && element.linkTo.target
    //       ? { target: element.linkTo.target }
    //       : {};
    //   const linkRel = linkTarget.target ? { rel: "noopener" } : {};
    //   const img = React.createElement("img", {
    //     src: element.url,
    //     alt: element.alt || "",
    //   });
    //   return React.createElement(
    //     "p",
    //     propsWithUniqueKey(
    //       { className: [element.label || "", "block-img mt-6 mb-6"].join(" ") },
    //       key
    //     ),
    //     linkUrl
    //       ? React.createElement(
    //           "a",
    //           Object.assign({ href: linkUrl }, linkTarget, linkRel),
    //           img
    //         )
    //       : img
    //   );

    case Elements.embed: // Embed
      props = Object.assign(
        {
          "data-oembed": element.oembed.embed_url,
          "data-oembed-type": element.oembed.type,
          "data-oembed-provider": element.oembed.provider_name,
        },
        element.label
          ? { className: element.label }
          : { className: "embed_responsive" }
      );
      const embedHtml = React.createElement("div", {
        dangerouslySetInnerHTML: { __html: element.oembed.html },
      });
      return React.createElement(
        "div",
        propsWithUniqueKey(props, key),
        embedHtml
      );

    case Elements.hyperlink: {
      // Hyperlinks
      let result = "";
      const url = PrismicLink.url(element.data, linkResolver);
      if (
        (element.data.link_type === "Web" &&
          element.data.url.startsWith("https://nwpsocal.com")) ||
        element.data.url.startsWith("http://localhost:8000")
      ) {
        let localUrl = element.data.url;
        if (element.data.url.startsWith("https://nwpsocal.com"))
          localUrl = localUrl.substring("https://nwpsocal.com".length);
        else if (element.data.url.startsWith("http://localhost:8000"))
          localUrl = localUrl.substring("http://localhost:8000".length);

        result = (
          <Link to={localUrl} key={key}>
            {content}
          </Link>
        );
      } else if (element.data.link_type === "Document") {
        result = (
          <a href={url} key={key}>
            {content}
          </a>
        );
      } else {
        const targetAttr = element.data.target
          ? { target: element.data.target }
          : {};
        const relAttr = element.data.target ? { rel: "noopener" } : {};
        props = Object.assign(
          {
            href: element.data.url || linkResolver(element.data),
            className: "underline text-grolens-green-800",
          },
          targetAttr,
          relAttr
        );
        result = React.createElement(
          "a",
          propsWithUniqueKey(props, key),
          children
        );
      }
      return result;
    }
    default:
      // Always include a default that returns null
      return null;
  }
};

export default htmlSerializer;
